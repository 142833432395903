import { CgTrack } from "react-icons/cg";
import { GiFertilizerBag, GiMedicalPack, GiWheat } from "react-icons/gi";
import { HiOutlineChevronDoubleLeft, HiOutlineMenu } from "react-icons/hi";
import {
  MdCategory,
  MdCompost,
  MdDashboard,
  MdGroup,
  MdInventory2,
  MdList,
  MdOutlineBatchPrediction
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import useIsAdmin from "../../hooks/useIsAdmin";
import { RootState } from "../../redux/app";
import { hideSidebar, showSidebar } from "../../redux/features/sidebarSlice";
import Logo from "../Logo/Logo";
import SideBarLink from "./SidebarLink";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { show } = useSelector((state: RootState) => state.sidebar);

  const { isAdmin } = useIsAdmin();


  return (
    <aside
      className={`sidebar z-20 shadow-xl fixed left-0 top-0 bottom-0 overflow-x-hidden box-border ${show ? "w-60" : "w-20"
        } bg-neutral-800 h-screen flex flex-col text-white transition-all`}
    >
      <header
        className={` ${show ? "justify-between" : "justify-center"
          } flex  items-center px-4 py-5 mb-2 noselect`}
      >
        {
          <div className={`${show ? "opacity-100" : "opacity-0 hidden"}`}>
            <Logo />
          </div>
        }

        {show ? (
          <button
            onClick={() => dispatch(hideSidebar())}
            className="text-white"
          >
            <HiOutlineChevronDoubleLeft size={21} />
          </button>
        ) : (
          <button
            className="text-white"
            onClick={() => dispatch(showSidebar())}
          >
            <HiOutlineMenu size={21} />
          </button>
        )}
      </header>

      <nav className="sidebar-links" id="sidebar">
        <ul>
          <SideBarLink
            to="/"
            Icon={MdDashboard}
            text="Dashboard"
            smallText="Dashboard"
            key="Dashboard"
          />

          <SideBarLink
            to="/users"
            list={[
              "/users",
              "/users/buyers",
              "/users/fpo",
              "/users/fpo/details",
              "/users/artisans",
              "/users/artisans/pending",
              "/users/artisans/approved",
            ]}
            Icon={MdGroup}
            text="Users"
            smallText="Users"
            key="Users"
          />

          {isAdmin && (<SideBarLink
            to="/products"
            list={["/products", "/products/deleted"]}
            Icon={MdList}
            text="Products"
            smallText="Products"
            key="Products"
          />)}

          {isAdmin && (<SideBarLink
            to="/categories"
            list={["/categories"]}
            Icon={MdCategory}
            text="Categories"
            smallText="Categories"
            key="categories"
          />)
          }


          {!isAdmin && (<SideBarLink
            to="/fpo-inventory"
            list={["/fpo-inventory"]}
            Icon={MdInventory2}
            text="FPO Inventory"
            smallText="FPO Inventory"
            key="fpoinventory"
          />)}

          {isAdmin && (<SideBarLink
            to="/product-formulation"
            list={["/product-formulation", "/product-formulation/transfer"]}
            Icon={MdOutlineBatchPrediction}
            text="Product Formulation"
            smallText="Product Formulation"
            key="Product Formulation"
          />)}

          <SideBarLink
            to="/track-and-trace"
            list={["/track-and-trace"]}
            Icon={CgTrack}
            text="Track and Trace"
            smallText="Track and Trace"
            key="trackandtrace"
          />

          {isAdmin && (<SideBarLink
            to="/crops"
            list={["/crops"]}
            Icon={GiWheat}
            text="Crops"
            smallText="Crops"
            key="crops"
          />
          )}

          {isAdmin && (<SideBarLink
            to="/fertilisers"
            list={["/fertilisers"]}
            Icon={GiMedicalPack}
            text="Ferilisers"
            smallText="Ferilisers"
            key="Ferilisers"
          />)}

          {isAdmin && (<SideBarLink
            to="/fertiliser/comb"
            list={["/fertiliser/comb"]}
            Icon={GiFertilizerBag}
            text="Ferilisers Combos"
            smallText="Ferilisers Combos"
            key="Ferilisers Combos"
          />)}

          {isAdmin && <SideBarLink
            to="/biomass-batch"
            list={["/biomass-batch"]}
            Icon={MdOutlineBatchPrediction}
            text="BioChar Batches"
            smallText="BioChar Batches"
            key="BioChar Batches"
          />}

          {isAdmin && (<SideBarLink
            to="/biomass-composition"
            list={["/biomass-composition"]}
            Icon={MdOutlineBatchPrediction}
            text="Biomass Config"
            smallText="Biomass Config"
            key="Biomass Config"
          />)}

          {<SideBarLink
            to="/pyrolysis-kiln"
            list={["/pyrolysis-kiln"]}
            Icon={MdOutlineBatchPrediction}
            text="Pyrolysis Kiln"
            smallText="Pyrolysis Kiln"
            key="Pyrolysis Kiln"
          />}

          {<SideBarLink
            to="/soil-pit"
            list={["/soil-pit"]}
            Icon={MdOutlineBatchPrediction}
            text="Soil Pit"
            smallText="Soil Pit"
            key="Soil Pit"
          />}

          {(<SideBarLink
            to="/measuring-vessel"
            list={["/measuring-vessel"]}
            Icon={MdOutlineBatchPrediction}
            text="Measuring Vessel"
            smallText="Measuring Vessel"
            key="Measuring Vessel"
          />)}

          {(<SideBarLink
            to="/compost-material"
            list={["/compost-material"]}
            Icon={MdCompost}
            text="Compost Material"
            smallText="Compost Material"
            key="Compost Material"
          />)}

          {isAdmin && (<SideBarLink
            to="/project"
            list={["/project"]}
            Icon={MdOutlineBatchPrediction}
            text="Project"
            smallText="Project"
            key="Project"
          />)}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
