import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Error404 from "../pages/Error404";

import Header from "../components/Header/Header";
import Loader from "../components/Loader/Loader";
import Sidebar from "../components/Sidebar/Sidebar";
import AllApprovedArtisan from "../pages/artisan/AllApprovedArtisan";
import AllArtisan from "../pages/artisan/AllArtisan";
import AllBatch from "../pages/biomass-batch/AllBatch";
import BiomassComposition from "../pages/biomass-composition";
import AllCategories from "../pages/categories/AllCategories";
import AllCrops from "../pages/crops/AllCrops";
import AllFertilisers from "../pages/fertilisers/AllFertilisers";
import AllFertilisersCombination from "../pages/fertilisersCombination/AllFertilisers";
import MeasuringVessel from "../pages/measuring-vessel";
import AllProductFormulation from "../pages/product-formulation/AllProductFormulation";
import AllProductTransfer from "../pages/product-formulation/AllProductTransfer";
import AllProducts from "../pages/products/AllProducts";
import DeletedProducts from "../pages/products/DeletedProducts";
import Projects from "../pages/project";
import PyrolysisKlin from "../pages/pyrolysis-kiln";
import SoilPit from "../pages/soil-pit";
import TrackAndTrace from "../pages/track-and-trace/TrackAndTrace";
import UserBuyers from "../pages/users/UserBuyers";
import { RootState } from "../redux/app";
import ArtisanLayout from "./ArtisanLayout";
import BiomassBatchLaoyout from "./BiomassBatchLayout";
import BioMassCompositionLayout from "./BioMassCompositionLayout";
import CategoryLayout from "./CategoryLayout";
import CompostMaterialLayout from "./CompostMaterialLayout";
import CropLayout from "./CropLayout";
import FertiliserLayoutCombination from "./FertiliserCombLayout";
import FertiliserLayout from "./FertiliserLayout";
import FPOInventoryLayout from "./FPOInventoryLayout";
import FPOLayout from "./FPOLayout";
import MeasuringVesselLayout from "./MeasuringVesselLayout";
import ProductFormulationLayout from "./ProductFormulationLayout";
import ProductsLayout from "./ProductLayout";
import PyrolysisKilnLayout from "./PyrolysisKilnLayout";
import SoilPitLayout from "./SoilPitLayout";
import UsersLayout from "./UsersLayout";


const Dashboard = lazy(() => import("../pages/Dashboard"));
const AllUsers = lazy(() => import("../pages/users/AllUsers"));
const UserSales = lazy(() => import("../pages/users/UserSales"));
const UserFpo = lazy(() => import("../pages/users/UserFpo"));
const FpoSeller = lazy(() => import("../pages/fpo-user-details/FpoSeller"));
const FpoBuyer = lazy(() => import("../pages/fpo-user-details/FpoBuyer"));
const FpoArtisan = lazy(() => import("../pages/fpo-user-details/FpoArtisan"));
const AllFPOProducts = lazy(() => import("../pages/fpo-inventory/AllFPOProducts"));
const AllFPOTransactions = lazy(() => import("../pages/fpo-inventory/AllFPOTransactions"));
const CompostMaterials = lazy(() => import("../pages/compost/CompostMaterials"));

const AdminLayout = () => {
  const { show } = useSelector((state: RootState) => state.sidebar);

  return (
    <div className="layout relative">
      <div className="layout__content flex">
        <Sidebar />

        <main
          className={`main flex-1 flex flex-col ${
            show ? "ml-60" : "ml-20"
          } transition-all`}
          style={{
            minHeight: "100vh",
          }}
        >
          <Header />

          <div className="page-wrapper flex-1 mt-4 px-12 pl-8 pb-24 box-border">
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Dashboard />} />

                <Route path="/users" element={<UsersLayout />}>
                  <Route index element={<AllUsers />} />
                  <Route path="sales" element={<UserSales />} />
                  <Route path="buyers" element={<UserBuyers />} />
                  <Route path="fpo" element={<UserFpo />} />
                </Route>

                <Route path="/users/fpo/details/:id" element={<FPOLayout />} >
                  <Route index element={<FpoSeller />} />
                  <Route path="sales" element={<FpoSeller />} />
                  <Route path="buyers" element={<UserBuyers />} />
                  <Route path="artisans" element={<FpoArtisan/>} />
                  <Route path="artisans/approved" element={<AllApprovedArtisan />} />
                </Route>
                

                <Route path="/products" element={<ProductsLayout />}>
                  <Route index element={<AllProducts />} />
                  <Route path="deleted" element={<DeletedProducts />} />
                </Route>

                <Route path="/categories" element={<CategoryLayout />}>
                  <Route index element={<AllCategories />} />
                </Route>

                <Route path="/fpo-inventory" element={<FPOInventoryLayout />}>
                  <Route index element={<AllFPOProducts />} />
                  <Route path="transactions" element={<AllFPOTransactions />} />
                </Route>

                <Route path="/compost-material" element={<CompostMaterialLayout />}>
                  <Route index element={<CompostMaterials />} />
                </Route>

                <Route path="/track-and-trace" element={<TrackAndTrace />} />

                <Route path="/crops" element={<CropLayout />}>
                  <Route index element={<AllCrops />} />
                </Route>

                <Route path="/fertilisers" element={<FertiliserLayout />}>
                  <Route index element={<AllFertilisers />} />
                </Route>

                <Route
                  path="/fertiliser/comb"
                  element={<FertiliserLayoutCombination />}
                >
                  <Route index element={<AllFertilisersCombination />} />
                </Route>

                <Route path="users/artisans" element={<ArtisanLayout />}>
                  <Route index element={<AllArtisan />} />
                  <Route path="approved" element={<AllApprovedArtisan />} />
                </Route>

                <Route path="/biomass-batch" element={<BiomassBatchLaoyout />}>
                  <Route index element={<AllBatch />} />
                </Route>
                <Route
                  path="/product-formulation"
                  element={<ProductFormulationLayout />}
                >
                  <Route index element={<AllProductFormulation />} />
                  <Route path="transfer" element={<AllProductTransfer />} />
                </Route>

                <Route
                  path="/biomass-composition"
                  element={<BioMassCompositionLayout />}
                >
                  <Route index element={<BiomassComposition />} />
                </Route>

                <Route path="/pyrolysis-kiln" element={<PyrolysisKilnLayout />}>
                  <Route index element={<PyrolysisKlin />} />
                </Route>

                <Route path="/soil-pit" element={<SoilPitLayout />}>
                  <Route index element={<SoilPit />} />
                </Route>

                <Route
                  path="/measuring-vessel"
                  element={<MeasuringVesselLayout />}
                >
                  <Route index element={<MeasuringVessel />} />
                </Route>

                <Route path="/project" element={<ProductsLayout />}>
                  <Route index element={<Projects />} />
                </Route>

                <Route path="/404" element={<Error404 />} />
                <Route
                  path="*"
                  element={<Navigate to="/404" replace={true} />}
                />
              </Routes>
              
            </Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
